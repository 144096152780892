import React, { useEffect, useRef, useState } from 'react';
import styles from './group.module.scss';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import { batchAddOrder, getWithBarcodeNumber } from '../../../services/pre-order.service';
import { toast } from 'react-toastify';
import BarcodeScanner from '../../barcode-scanner/barcode-scanner';
import Scanner from '../../scanner/scanner';

import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/actions';
import Swal from 'sweetalert2';


const Group = () => {

    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [orderGroup, setOrderGroup] = useState<Array<any>>([])
    const [loader, setLoader] = useState<boolean>(false);
    const batchLoader = useRef<boolean>(false)
    const [openContent, setOpenContent] = useState<boolean>(true);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const params = useParams();
    const barcodeRef = useRef<any>();
    const dispatch = useDispatch();
    const socket = useRef<any>()
    const activeBarcode = useRef<string>('');



    useEffect(() => {
        loadSocket();

        return () => {
            closeSocket();
        }
    }, [])


    const closeSocket = () => {
        orderGroup.map(item => {
            const closeStream = { "arguments": [item.preOrderId], "invocationId": "0", "target": "UnsubscribePreOrderToOrderStream", "type": 1 }
            if (socket.current) {
                sendMessage(closeStream);
            }
        })
        if (socket.current) {
            socket.current.close();
        }
    }


    const openCamera = () => {
        setTimeout(() => {
            if (scanMode === 'camera') {
                setScan(true)
            }
        }, 2000)
    }

    const loadSocket = () => {
        const token = localStorage.getItem('token')
        socket.current = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}MainHub?access_token=${token}`)
        socket.current.onopen = () => {
            console.log('opended')
            const firstMessage = { "protocol": "json", "version": 1 }
            sendMessage(firstMessage)

        }
        socket.current.onerror = (e: any) => {
            console.log('error', e)
        }

        socket.current.onmessage = (data: any) => {
            const message = JSON.parse(data.data.replace(//g, ''));

            if (message?.arguments && message.arguments.length > 0 && !message?.arguments[0]?.success) {
                audioErrorRef.current.play();
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn",
                        cancelButton: "btn btn-danger"
                    },
                    buttonsStyling: false,

                });
                swalWithBootstrapButtons.fire({
                    icon: "error",
                    title: "Hata...",
                    text: message.arguments[0].message,
                    confirmButtonText: "Tamam",
                    confirmButtonColor: "#fd7e14",
                    didClose: () => {
                        barcodeRef.current?.focus();
                        setBarcodeNumber('');
                    }
                })

                openCamera();



            }
            else if (message.arguments && message.arguments.length > 0) {
                if (message.target == 'NewScannedPreOrders') {
                    const order = message.arguments[0].data;
                    if (order) {
                        setToStrage(activeBarcode.current);
                        addOrderGroup(order);
                        audioSuccessRef.current?.play();
                        setBarcodeNumber('');
                    }
                     openCamera();
                }
                else if (message.target == 'GetNewScannedPreOrders') {
                    const data = message.arguments[0].data;
                    const index = orderGroup.findIndex(item => item.preOrderId === data.preOrderId);
                    if (index > -1) {
                        setToStrage(data.barcode);
                        addOrderGroup(orderGroup[index]);
                        audioSuccessRef.current?.play();
                        setBarcodeNumber('');
                    }
                    openCamera();
                }

            }

        }
    }



    const sendMessage = (message: any) => {
        socket.current.send(JSON.stringify(message) + '')
    }


    const search = async (barcode?: string) => {
        if (barcodeNumber || barcode) {
            const inputBarcode: any = barcode ? barcode : barcodeNumber;
            activeBarcode.current = inputBarcode;
            const loadMessage = { "arguments": [inputBarcode], "target": "ScanAPreOrderBarcodeToCreateAnOrder", "type": 1 }
            sendMessage(loadMessage);
        }
    }




    const searcho = async (barcode?: string) => {
        if (barcodeNumber || barcode && !batchLoader.current) {
            const inputBarcode: any = barcode ? barcode : barcodeNumber;

            setScan(false);
            setLoader(true);
            const forceAssign: boolean = params.forceAssign ? true : false;
            const res = await getWithBarcodeNumber(inputBarcode, forceAssign);
            setLoader(false)
            const order = res.data.data;
            if (order) {
                setToStrage(inputBarcode);
                addOrderGroup(order);
                audioSuccessRef.current.play();

            }
            else {
                audioErrorRef.current.play();
                toast.error(res.data.message, {
                    position: "bottom-center",
                });

            }

            setBarcodeNumber('');

            if (!batchLoader.current) {
                barcodeRef.current?.focus();
            }
            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }

    const addOrderGroup = (order) => {

        const index = orderGroup.findIndex(item => item.preOrderCode === order.preOrderCode);
        if (index === -1) {
            const orders = orderGroup;
            orders.unshift(order);
            checkVisitedBarcodes(orders)
        }
        else {
            checkVisitedBarcodes(orderGroup)
        }

    }


    const checkVisitedBarcodes = (orders) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('barcodes') as string;
        if (storage) {
            barcodeList = JSON.parse(storage);
            orders = orders.map(order => {
                order.readed = [];
                order.preOrderBarcodes = order.preOrderBarcodes.map(preOrder => {
                    const index = barcodeList.findIndex(a => a === preOrder.no);
                    if (index > -1 || preOrder.isScanned) {
                        preOrder.visited = true;
                        order.readed.push(preOrder);
                        // allBarcodesReaded(order)
                    }

                    return preOrder;

                })

                return order;
            })


            setOrderGroup([...orders]);

        }


    }

    const allBarcodesReaded = (order) => {
        if (order.readed.length === order.preOrderBarcodes.length && !order?.done) {
            //   doneReadedBarcodes([order]);
            const index = orderGroup.findIndex(item => item.preOrderCode === order.preOrderCode);
            if (index > -1) {
                orderGroup[index].done = true;
                setOrderGroup([...orderGroup])
            }
        }

    }

    const doneReadedBarcodes = async (order) => {
        dispatch(showLoading())
        barcodeRef.current?.blur();
        batchLoader.current = true;
        const res = await batchAddOrder(order);
        batchLoader.current = false;
        dispatch(hideLoading())
        barcodeRef.current?.focus();
        if (res.data.success) {
            const index = orderGroup.findIndex(item => item.preOrderCode === order[0].preOrderCode);
            if (index > -1) {
                orderGroup[index].done = true;
                setOrderGroup([...orderGroup])
            }
            toast.success('İşlem Başarılı.', {
                position: "bottom-center",
            })
        }
        else {
            toast.error(res.data.message, {
                position: "bottom-center",
            });
        }
    }

    const setToStrage = (barcode) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('barcodes') as string;
        if (storage) {
            barcodeList = JSON.parse(storage);
            const index = barcodeList.findIndex(a => a === barcode);
            if (index === -1) {
                barcodeList.push(barcode);
            }
        }
        else {
            barcodeList.push(barcode);
        }

        localStorage.setItem('barcodes', JSON.stringify(barcodeList))

    }






    const toggle = (item: any) => {
        const index = orderGroup.findIndex(a => a.preOrderCode === item.preOrderCode);
        orderGroup[index].openContent = orderGroup[index].openContent ? !orderGroup[index].openContent : true;
        setOrderGroup([...orderGroup])
    }

    const generateOrderGroups = (readed: boolean = false) => {
        let order = orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length);;
        if (readed) {
            order = orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length);
        }

        return order.map((item, index) => {
            return (
                <>

                    <div key={item.preOrderCode} className={styles.list}>

                        <div className={styles.item}>

                            <div className={`${styles.title} ${readed ? styles.green : ''} ${!item.openContent ? styles.noBorder : null}`} onClick={e => toggle(item)}>
                                <span>seperis No: {item?.preOrderCode}</span>
                                <i className='fa fa-angle-down'></i>
                            </div>

                            {item.openContent ? (
                                <div className={styles.content} >
                                    <div className={styles.barcodeLength}>
                                        Okutulan / Toplam Barkod: {item.readed?.length}/{item?.preOrderBarcodes?.length}
                                    </div>


                                    {item?.preOrderBarcodes?.map(barcode => {
                                        return (
                                            <div key={barcode.no} className={`${styles.items} ${barcode?.visited ? 'text-success' : 'text-danger'}`}>
                                                {barcode.no}
                                            </div>
                                        )
                                    })}

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Gönderen</div>
                                        <div className={styles.Value}> {item?.senderCustomerName} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Gönderen Adresi</div>
                                        <div className={styles.Value}> {item?.senderCustomerFriendlyAddress} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Alıcı</div>
                                        <div className={styles.Value}> {item?.receiverCustomerName} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}>  Alıcı Adresi</div>
                                        <div className={styles.Value}> {item?.receiverCustomerFriendlyAddress} </div>
                                    </div>

                                </div>
                            ) : null}



                        </div>

                    </div>


                </>

            )
        })

    }

    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }

    const getReadingBarcodes = () => {
        let barcodesLength = 0;
        let readedBarcodesLength = 0;
        orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).map(item => {
            barcodesLength += item.preOrderBarcodes.length;
            readedBarcodesLength += item.preOrderBarcodes.filter(a => a.visited).length;
        });
        const data = {} as any;
        data.all = barcodesLength;
        data.visited = readedBarcodesLength;
        return data;
    }

    const getDoneBarcodes = () => {
        let barcodesLength = 0;
        orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).map(item => {
            barcodesLength += item.preOrderBarcodes.length;
        });
        return barcodesLength;
    }


    const onCloseCamera = () => {
        setScan(false);
        setScanMode('manaul');
        setBarcodeNumber('');
        barcodeRef.current?.focus();
    }


    return (
        <>

            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className={styles.group}>

                <div className={`${styles.infos} box-item`}>
                    <div className={styles.infoItems}>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Işlem yapılan Gönderi:</div>
                            <div className={styles.value}>{orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).length}</div>

                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Işlem yapılan barkod:</div>
                            <div className={styles.value}>{getReadingBarcodes()?.visited}/{getReadingBarcodes()?.all}</div>
                        </div>
                    </div>
                    <div className={styles.infoItems}>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Gönderisi oluşturulanlar:</div>
                            <div className={styles.value}>{orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).length}</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Gönderisi oluşturuldi barkod :</div>
                            <div className={styles.value}>{getDoneBarcodes()}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.barcode}>
                    <input
                        id='barcodeRef'
                        readOnly={loader}
                        ref={barcodeRef}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}
                        value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
                    <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Ara2</button>
                    <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                        <Icon path={mdiBarcode}
                            title={''}
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color={'black'}
                        // spin
                        />
                    </button>


                </div>





                {scan ? (
                    <div className={styles.video}>
                        <Scanner onClose={onCloseCamera} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                    </div>
                ) : null}


                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}



                {orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).length > 0 ? (
                    <div className={styles.groupSection}>

                        <div className={styles.groupTitle}>
                            İşlem yapılanlar
                        </div>
                        <div className={styles.part}>

                            {generateOrderGroups()}
                        </div>
                    </div>
                ) : null}

                {orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).length > 0 ? (
                    <>

                        <div className={styles.groupSection}>
                            <div className={styles.groupTitle}>
                                Gönderisi oluşturulanlar
                            </div>
                            <div className={styles.part}>

                                {generateOrderGroups(true)}

                            </div>
                        </div>

                    </>
                ) : null}


            </div >

        </>
    )

}

export default Group;